import React, { useEffect, useState } from 'react';
import { JobApplyPageTexts as Texts } from '../../helpers/LayoutTexts';
import { TextInput } from '../Shared/Inputs/TextInput';
import Dropzone from 'react-dropzone';
import { ReactComponent as FileUpload } from '../../assets/svg/file-upload.svg';
import { ReactComponent as ExternalLink } from '../../assets/svg/external-link.svg';
import { SubmitArea } from './SubmitArea';
import { JobApplyModel } from '../../models/JobApplyModel';
import useErrorPage from '../../hooks/useErrorPage';
import { DraftApplication } from '../../models/DraftApplication';
import { getCandidateDraftApplication } from '../../services/CandidateService';
import { getJobApplyStatus } from '../../services/JobService';
import { ResponseModel } from '../../models/ResponseModel';
import { Button } from '../Shared/Buttons/Button';
import { PopUpWindow } from '../Shared/PopUpWindow';
import { useNavigate } from 'react-router-dom';
import { DocumentViewer } from '../Shared/DocumentViewer';
import { ReactComponent as GreenTick } from '../../assets/svg/check-circle.svg';
import { ReactComponent as CloseCircle } from '../../assets/svg/circle-close.svg';
import { getCandidateCVURL, validateCV } from '../../helpers/Common';
import { LoggedCandidate } from '../../models/LoggedCandidate';
import { QuillEditor } from '../Shared/QuillEditor';

interface Props {
    styles: any;
    jobId: number;
    candidate: LoggedCandidate;
    draftEnable: boolean;
    isDraft?: boolean;
    isDraftCV?: boolean;
    handleApply(formInputs: JobApplyModel, uploadedCV: File, saveAsDraft: boolean): Promise<any>;
    isMobile: boolean;
    openAlert(status: string, message: string): void;
}

export const ApplyForm: React.FC<Props> = (props) => {
    const styles = props.styles;
    const candidate = props.candidate;        
    const intialFormInputs: JobApplyModel = {
        JobId: props.jobId,
        CandidateId: candidate.candidateId,
        FullName: candidate.forename + " " + candidate.surname,
        Email: candidate.email
    }
    const [uploadedCV, setUploadedCV] = useState<File | null>(null);
    const [formInputs, setFormInputs] = useState<JobApplyModel>(intialFormInputs);
    const [dialogContent, setDialogContent] = useState<any>();
    const [openMessageDialog, setOpenMessageDialog] = useState<boolean>(false);
    const [openCVDialog, setOpenCVDialog] = useState<any>();
    const navigate = useNavigate();
    const [toErrorPage] = useErrorPage();
    const coverLetterText: RegExp = /<[^>]+>(\s*[^<>\s]+\s*)+<\/[^>]+>/;

    useEffect(() => {
        if(props.isDraft) {
            getDraftApplication();
        }                
        getApplyStatus();        
    }, []);

    async function getApplyStatus() {
        await getJobApplyStatus(candidate.candidateId, props.jobId, candidate.token)
        .then((response: ResponseModel) => {
            if(response.status) {
                let content = <>
                    <h1 style={styles.dialogTitle}>{response.data}</h1>    
                    <p style={styles.dialogMessage}>{response.message}</p>
                    <div style={styles.dialogButtons}>
                        <Button style={styles.closeButton} type="button" onClick={closeMessageDialog}>{"Close"}</Button>
                        <Button style={styles.redirectButton} type="button" onClick={() => navigate("/candidate/myapplications")}>{"MY JOB LISTING"}</Button>                        
                    </div>                       
                </> 
                setDialogContent(content);
                setOpenMessageDialog(true);           
            }
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const loadFileToPopup = () => {
        setOpenCVDialog(true);
    }

    async function getDraftApplication() {
        await getCandidateDraftApplication(candidate.candidateId, props.jobId, candidate.token)
        .then((draftApplication: DraftApplication) => {
            const draftFormInputs: JobApplyModel = {
                JobId: props.jobId,
                CandidateId: candidate.candidateId,
                FullName: draftApplication.fullName,
                Email: draftApplication.email,        
                CoverLetter: draftApplication.coverLetter,
                IsDraft: props.isDraft,
                IsDraftCV: props.isDraftCV
            };
            setFormInputs(draftFormInputs);
        })
        .catch((error) => {            
            toErrorPage(error);
        });
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs((values: any) => ({ ...values, [name]: value }));
    }

    const handleFileChange = (uploadedFiles: File[]) => {   
        if(uploadedFiles.length > 0) {
            const file = uploadedFiles[0];
            const validation = validateCV(file);
            if(validation.valid) {
                setUploadedCV(file);
            } else {
                props.openAlert("error",validation.invalidMessage!);
            }
        }        
    }

    const removeUploadedFile = () => {
        setUploadedCV(null);
    }
    
    const handleApply = (event: React.FormEvent<HTMLFormElement>, saveAsDraft: boolean) => {
        event.preventDefault();
        if(formInputs.CoverLetter) {
            formInputs.CoverLetter = coverLetterText.test(formInputs.CoverLetter) ? formInputs.CoverLetter : undefined;
        }
        props.handleApply(formInputs, uploadedCV!, saveAsDraft);
    }

    const closeMessageDialog = () => {
        const redirectUrl = sessionStorage.getItem("redirectUrl");
        setOpenMessageDialog(false);
        if(redirectUrl){
            navigate(redirectUrl);
        }
        else {
            navigate({ pathname: '/jobs' });
        }
    }

    const closeCVDialog = () => {
        setOpenCVDialog(false);
    }

    return (
        <>
            <form onSubmit={(e) => handleApply(e, false)}>
                <h5 style={styles.applyStage}>{Texts.applyStage.apply}</h5>
                <p style={styles.stageDescription}>{Texts.stageDescription.apply}</p>
                <div style={styles.inputGroup}>
                    <div style={styles.inputContainer}>
                        <span style={{...styles.label, ...styles.label.leftSpaced}}>{Texts.fullName}</span>
                        <TextInput
                            inputType={'text'}
                            name={'FullName'}
                            value={formInputs.FullName}
                            handleChange={handleInputChange}
                            disabled
                            styles={styles.inputField}
                        />
                    </div>
                    <div  style={styles.inputContainer}>
                        <span style={{...styles.label, ...styles.label.leftSpaced}}>{Texts.email}</span>
                        <TextInput
                            inputType={'text'}
                            name={'Email'}
                            value={formInputs.Email}
                            handleChange={handleInputChange}
                            disabled
                            styles={{...styles.inputField, ...styles.inputField.leftSpaced}}
                        />
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <div style={styles.resumeSectionTexts}>
                        <label style={styles.cvTxtLabel}>{Texts.cv}</label>
                        <p style={styles.cvDescription}>{Texts.cvDescription}</p>
                        {uploadedCV && <>
                            <label style={styles.uploadedCVText}>Selected CV :</label>
                            <p style={styles.uploadedCV}>{uploadedCV?.name}</p>
                        </>}
                    </div>
                    <div style={styles.cvHolder}>
                        <div style={styles.existingCV}>
                            <DocumentViewer styles={styles.documentViewer} document={uploadedCV ? uploadedCV : getCandidateCVURL(candidate.cvTranslationText, props.isDraftCV)}/>
                            <div style={styles.existingCvLayer}>
                                <div style={styles.existingCvLabel}>
                                    <div style={styles.existingCvName}>{uploadedCV?.name}</div>
                                    <label style={styles.viewCV} onClick={loadFileToPopup}>View CV <ExternalLink style={styles.viewCVIcon} /></label>
                                </div>
                            </div>
                            <GreenTick style={styles.greenTick} />
                            {uploadedCV && <CloseCircle style={styles.removeCV} onClick={removeUploadedFile}/>}
                        </div>
                        <Dropzone onDrop={acceptedFiles => handleFileChange(acceptedFiles)} multiple={false}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ style: styles.uploadCV })}>
                                    <input {...getInputProps()} />
                                    <div style={styles.uploadCVTexts}>
                                        <div style={styles.uploadIconWrapper}><FileUpload style={styles.uploadIcon}/></div>
                                        <div style={styles.uploadCVtxt}>{props.isMobile ? Texts.uploadCVMobile : Texts.uploadCV}</div>
                                        <div style={styles.uploadCVMaxFileSize}>{Texts.maxFileSize}</div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </div>
                <div style={styles.coverLetterLabel}>{Texts.coverLetter}</div>
                <QuillEditor styles={styles.coverLetterEditor} value={formInputs.CoverLetter} onChange={(content: string) => setFormInputs({ ...formInputs, CoverLetter: content })}/>            
            </form>
            <SubmitArea submitButtonText={Texts.applyButton} submitNotice={Texts.applyNotice} draftEnable={props.draftEnable} handleSubmit={(e) => handleApply(e, false)} handleDraft={(e) => handleApply(e, true)}/>
            <PopUpWindow styles={styles.cvDialog.popup} open={openCVDialog} toggle={closeCVDialog} closeCircle={true}>
                <DocumentViewer styles={styles.cvDialog.document} document={uploadedCV ? uploadedCV : getCandidateCVURL(candidate.cvTranslationText, props.isDraftCV)}/>
            </PopUpWindow>
            <PopUpWindow styles={styles.dialog} open={openMessageDialog} toggle={closeMessageDialog} closeSlice={!props.isMobile} closeCircle={props.isMobile}>
                {dialogContent}
            </PopUpWindow>
        </>
    )
}