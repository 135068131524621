import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive'
import { DesktopMenu } from './DesktopMenu';
import { XpressJobsTitle } from './XpressJobsTitle';
import { Button } from './Buttons/Button';
import { SearchArea } from './SearchArea';
import { MenuItem } from '../../models/MenuItem';
import { createSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { StyleContext } from '../../providers/StyleContextProvider';
import { AuthContext } from '../../providers/AuthContextProvider';
import { ReactComponent as Disable } from '../../assets/svg/disable.svg';
import { ReactComponent as MobileMenu } from '../../assets/svg/mobile_menu.svg';
import { ReactComponent as Notification } from '../../assets/svg/notification.svg';
import { DropdownItem } from './DropdownItem';
import { MobileMenuDrawer } from './MobileMenuDrawer';
import { Dropdown } from './Dropdown';
import { Recruiter } from '../../models/Recruiter';
import { ReactComponent as DesktopHeaderLeftSvg } from '../../assets/svg/desktop-header-left.svg';
import { ReactComponent as LongBuildingSvg } from '../../assets/svg/desktop-header-right.svg';
import { ReactComponent as StartCurve } from '../../assets/svg/header-start-curve.svg';
import { ReactComponent as EndCurve } from '../../assets/svg/header-end-curve.svg';
import { ReactComponent as LeftArrow } from '../../assets/svg/left_arrow.svg';
import { ReactComponent as BookmarkActive } from '../../assets/svg/bookmark-active.svg';
import { getCandidateProfilePictureURL, getHost, getSEOKeyword } from '../../helpers/Common';
import { ReactComponent as LogoutIcon } from '../../assets/svg/logout.svg';
import { ReactComponent as Cart } from '../../assets/svg/shopping-cart.svg';
import { LoggedCandidate } from '../../models/LoggedCandidate';
import { postCandidateLogout } from '../../services/CandidateService';

interface Props {
    styleSheet: string;    
    searchEnable?: boolean;
    searchSelectEnable?: boolean;    
    keyword?: string;
    locationIds?: string;
    heading?: string;
    text?: string;
    searchText?: string;
    jobTitle?: string;
    organization?: string;
    isDisabilityFriendly?: boolean;
    backButtonText?: string;
    backButtonEnable?: boolean;
    handleBackButtonClick?(): void;
    jobSaved?: boolean;
}

export const Header: React.FC<Props> = (props) => {  
    const navigate = useNavigate();
    const location = useLocation();    
    const baseURL = getHost();    
    const searchParams = new URLSearchParams(location.search);               
    const styleContext = useContext(StyleContext);
    const authContext = useContext(AuthContext);
    const candidate: LoggedCandidate | null = authContext.getLoggedCandidate();
    const recruiter: Recruiter | null = authContext.getLoggedRecruiter();
    const [styles, setStyles] = useState(styleContext.getComponentStyle(props.styleSheet));                                 
    const isMobile = useMediaQuery({ query: "(max-width: 786px)" });
    const [mobileMenuToggle, setMobileMenuToggle] = useState<boolean>(false); 
    const [keyword, setKeyword] = useState<string>(props.keyword ? props.keyword : "");
    const [locationIds, setLocationIds] = useState<string>(props.locationIds ? props.locationIds : "");     
    const [locationNames, setLocationNames] = useState<string>("");    
    const candidateProfilePictureURL = getCandidateProfilePictureURL(candidate?.candidateId!);
    const isJobsPage = location.pathname.includes('/jobs');

    useEffect(() => {
        setStyles(styleContext.getComponentStyle(props.styleSheet));
    }, [isMobile, props.styleSheet]);
    
    const redirectByUser = () => {
        if(candidate !== null) {                        
            navigate('/candidate/home', { state: { candidateId: candidate.candidateId }});
        } 
        else if(recruiter !== null) {                        
            //navigate('/recruiterHome', { state: { recruiterId: recruiter.recruiterId }});
        } 
        else {                        
            navigate('/');
        } 
    }

    useEffect(() => {
        setLocationIds(props.locationIds || "");
    }, [props.locationIds]);

    const redirectJobs = (byCVLess: string) => {
        if(isJobsPage) {
            searchParams.set("page", "1");
            navigate({
                pathname: "/jobs" + byCVLess,
                search: `?${searchParams}`
            });
            if(isMobile) {
                handleMobileMenuToggle();
            }
        }
        else {
            navigate("/jobs" + byCVLess);
        }        
    }

    const redirectWalkIn = () => {
        if(isJobsPage) {
            searchParams.set("page", "1");
            navigate({
                pathname: "/jobs/walkIn",
                search: `?${searchParams}`
            });
            if(isMobile) {
                handleMobileMenuToggle();
            }
        }
        else {
            navigate("/jobs/walkIn");
        }
    }

    const handleRecruitersButtonClick = () => {
        window.location.assign(baseURL + "for-recruiters");
    }
    
    const handleJobSeekersButtonClick = () => {                        
        if (candidate !== null) {                        
            navigate('/candidate/home', { state: { candidateId: candidate!.candidateId }});
        } 
        else {
            navigate('/candidates/login');
        }
    }
        
    const handleMobileMenuToggle = () => {        
        setMobileMenuToggle((prev) => !prev);
    }

    const handleNotifyButtonClick = () => {

    }

    const candidateLogout = () => {
        localStorage.removeItem("candidate");
        localStorage.removeItem("savedJobIds");
        localStorage.removeItem("token");
        postCandidateLogout(candidate!.candidateId);
        navigate('/candidates/login');
    }

    const handleBackButtonClick = () => {
        props.handleBackButtonClick ? props.handleBackButtonClick() : navigate(-1);
    }

    const redirectToSchool = () => {
        window.open('https://school.xpress.jobs/', '_blank');
    }

    const handleSearch = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        let seoKeyword = getPageSEO();
        if(isJobsPage) {            
            searchParams.delete("Locations");
            searchParams.delete("KeyWord");
            searchParams.delete("SeoKeyword");
            if(keyword !== "") {
                searchParams.set("KeyWord", keyword);
            } 
            if(locationIds !== "") {
                searchParams.set("Locations", locationIds);
            }
            if(seoKeyword !== "") {
                searchParams.set("SeoKeyword", seoKeyword);
            }
            searchParams.set("page", "1");
            const newUrl = `?${searchParams}`;
            navigate(newUrl);
        }
        else {
            const newSearchParams = createSearchParams();
            if(keyword !== "") {
                newSearchParams.set("KeyWord", keyword);
            }
            if(locationIds !== "") {
                newSearchParams.set("Locations", locationIds);
            }
            if(seoKeyword !== "") {
                newSearchParams.set("SeoKeyword", seoKeyword);
            } 
            navigate({
                pathname: '/jobs',
                search: `?${newSearchParams}`
            }); 
        }
    }

    const menuItems: MenuItem[] = [
        { isList: false, item: <span onClick={redirectByUser} style={styles.label}>{"Home"}</span> },
        { isList: true, label: "Jobs", items:[] = [
            { item:                 
                <div style={styles.dropdownLabel}>
                    <DropdownItem onClick={() => redirectJobs("")} styles={styles.dropdownItem}>{"All Jobs"}</DropdownItem>
                </div>                
            },
            { item: 
                <div style={styles.dropdownLabel}>
                    <DropdownItem onClick={() => redirectJobs("/cvless")} styles={styles.dropdownItem}>{"CV-Less Jobs"}</DropdownItem>
                </div>    
            },
            { item: 
                <div style={styles.dropdownLabel}>
                    <DropdownItem onClick={() => redirectWalkIn()} styles={styles.dropdownItem}>{"Walk-In Interviews"}</DropdownItem>
                </div>                
            }] 
        },       
        { isList: true, label: "Explore", styles: styles.desktopMenu.explore, items: [] = [
            { item: 
                <Link to={"/testimonials/recruitment"} style={styles.dropdownLabel}>
                    <DropdownItem styles={styles.dropdownItem}>{"Testimonials"}</DropdownItem>
                </Link>                
            },
            { item: 
                <Link to={"/press"} style={styles.dropdownLabel}>
                    <DropdownItem styles={styles.dropdownItem}>{"Press Releases"}</DropdownItem>
                </Link>                    
            },
            { item:
                <Link to={"/home/preferredPartners"} style={styles.dropdownLabel}>
                    <DropdownItem styles={styles.dropdownItem}>{"Partners"}</DropdownItem>
                </Link>                    
            },
            { item:
                <Link to={"/home/differentlyabledfriendlyemployment"} style={styles.dropdownLabel}>
                    <DropdownItem styles={styles.dropdownItem}>{"Differently-Abled Friendly Employment"}</DropdownItem>
                </Link>                    
            }]
        },
        { isList: false, item: <Link to={'/contact'} style={styles.label}>{"Contact us"}</Link> },      
        { isList: true, 
            label: candidate ? <div style={styles.storeContainer}> <Cart style={styles.storeIcon} /> <span style={styles.storeLabel}> STORE </span> </div> : "Store", 
            styles: candidate ? styles.desktopMenu.storeButton: styles.desktopMenu.store, 
            items:[] = [
                { item: 
                    <Link to={'/cvFormats'} style={styles.dropdownLabel}>
                        <DropdownItem styles={styles.dropdownItem}>{"CV Formats"}</DropdownItem>
                    </Link>              
                },
                // { item: 
                //     <Link to={'/smsRegister'} style={styles.dropdownLabel}>
                //         <DropdownItem styles={styles.dropdownItem}>{"SMS Job Alert"}</DropdownItem>
                //     </Link>
                // },
                { item: 
                    <div style={styles.dropdownLabel}>
                        <DropdownItem onClick={() => redirectToSchool()} styles={styles.dropdownItem}>{"Online Courses"}</DropdownItem>
                    </div>
                }
            ] 
        }
    ]  

    const candidateProfileItems: any[] = [
        { item: 
            <Link to={'/candidate/updateprofile'} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"My Profile"}</DropdownItem>
            </Link>              
        },
        { item: 
            <Link to={'/candidate/myapplications'} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Status of Applications"}</DropdownItem>
            </Link>
        },
        { item: 
            <Link to={'/candidate/managesavedjobs'} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Saved Jobs"}</DropdownItem>
            </Link>
        },
        { item: 
            <Link to={'/candidate/updateprofile'} style={styles.dropdownLabel}>
                <DropdownItem styles={styles.dropdownItem}>{"Edit My Profile"}</DropdownItem>
            </Link>
        },
        { item: 
            <div style={{...styles.dropdownLabel, ...styles.logoutDropdownLabel}}>
                <DropdownItem onClick={candidateLogout} styles={styles.logoutDropdownItem}>
                    {"Logout"}
                    <LogoutIcon style={styles.logoutIcon} />
                </DropdownItem>                
            </div>
        }   
    ] 
    
    const getPageSEO = (): string => {
        let seoKeyword = keyword + " jobs " + locationNames;
        seoKeyword = seoKeyword.trim();
        if(seoKeyword !== "jobs") {
            seoKeyword = getSEOKeyword(seoKeyword);
        }
        else {
            seoKeyword = "";
        }
        return seoKeyword;
    }

    return (
        <>
            <div style={styles.header}>
                <DesktopHeaderLeftSvg style={styles.desktopImageLeft}/>
                <LongBuildingSvg style={styles.longBuildingSvg}/>
                <StartCurve style={styles.startCurve}/>
                <EndCurve style={styles.endCurve}/>
                {/* This is loading only mobile platform */}
                <MobileMenuDrawer open={mobileMenuToggle} toggle={handleMobileMenuToggle} menuItems={menuItems} recruitersLogin={handleRecruitersButtonClick} jobSeekersLogin={handleJobSeekersButtonClick} candidate={candidate} recruiter={recruiter} candidateProfileItems={candidateProfileItems} profilePictureURL={candidateProfilePictureURL}/>
                {props.backButtonEnable && <div style={styles.backButton.wrapper} onClick={handleBackButtonClick}>
                    <Button style={styles.backButton.button}><LeftArrow style={styles.backButton.icon} /></Button>
                    <span style={styles.backButton.text}>{props.backButtonText}</span>
                </div>}
                <div style={styles.row}>
                    <XpressJobsTitle style={styles.title}/>
                    {/* This is loading only desktop platform */}
                    <DesktopMenu menuItems={menuItems}/>
                    {candidate && <div style={styles.userWrapper}>
                        {/* <Button type="button" style={styles.notifyButton} onClick={handleNotifyButtonClick}><Notification style={styles.notifyIcon}/></Button> */}
                        <Dropdown styles={styles.userDropdown} items={candidateProfileItems}>
                            <img src={candidateProfilePictureURL} style={styles.userAvatar}/>
                        </Dropdown>
                    </div>}
                    {!candidate && !recruiter && <div style={styles.loginWrapper}>
                        <Button type="button" style={styles.recruitersButton} onClick={handleRecruitersButtonClick}>{"RECRUITERS LOGIN"}</Button>
                        <Button type="button" style={styles.jobSeekersButton} onClick={handleJobSeekersButtonClick}>{"JOB SEEKERS LOGIN"}</Button>
                    </div>}
                    <Button type="button" style={styles.mobileMenuButton} onClick={handleMobileMenuToggle}><MobileMenu/></Button>
                </div>
                {props.heading && <h2 style={{...styles.heading, ...(candidate && styles.heading.moveDown)}}>{props.heading}</h2>}
                {props.text && <p style={styles.text}>{props.text}</p>}
                {props.jobTitle && <div style={styles.jobTitleWrapper}>
                    <h2 style={styles.jobTitle}>{props.jobTitle}</h2>
                    {props.jobSaved && <div style={styles.savedJobWrapper}>
                        <BookmarkActive style={styles.savedJobIcon}/>
                        <span style={styles.savedJobText}>Saved</span>
                    </div>}
                </div>}
                <div style={styles.organization}>
                    {props.organization}{props.isDisabilityFriendly && <Disable style={styles.organization.icon} className="white-svg"/>}
                </div>
            </div>
            {props.searchEnable && 
                <SearchArea 
                    placeholder={props.searchText} 
                    inputValue={keyword} 
                    setInputValue={setKeyword} 
                    selectEnable={props.searchSelectEnable} 
                    selectedIds={locationIds} 
                    setSelectedIds={setLocationIds} 
                    setSelectedNames={setLocationNames} 
                    buttonText={"SEARCH"} 
                    handleSearch={handleSearch}                     
                    isJobsPage={isJobsPage}
                />
            }
        </>
    );
}